.about-us-container {
  padding: 50px;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-header {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
}

.about-text {
  font-size: 22px;
  line-height: 1.5;
  margin-bottom: 20px;
  max-width: 800px;
  text-align: center;
}

.about-list {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
  max-width: 800px;
  text-align: center;
  block-size: auto;
}

.about-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.about-link {
  color: #333;
  font-size: 24px;
  margin: 20px;
  display: flex;
  align-items: center;
}

.about-link:hover {
  color: #4169E1;
}

.about-link svg {
  font-size: 32px;
  margin-right: 10px;
}
