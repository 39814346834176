html, body {
  height: 100%;
  width: 100%;
  background-color: white;
}
.selected {
  background-color: #00796b;
  color: white;
  font-family: 'Merriweather', serif !important;
}
  #physical {
    font-family: 'Merriweather', serif !important;
    color: #595959 !important
  }
  #historical {
    font-family: 'Merriweather', serif !important;
    color: #595959 !important
  }
  #home {
    font-family: 'Merriweather', serif !important;
    color: #595959 !important
  }
  
  button {
    font-family: 'Merriweather', serif !important;
    border-radius: 0 !important;
    border: solid 1px silver;
    padding: 5px !important;

  }
  .heading {
    margin-top: 100px !important;
  }
  .subheading {
    margin-bottom: 20px !important;
  }
  /* .css-o4b71y-MuiAccordionSummary-content {
    display: block !important;
    text-align: center;
    color: white !important;
  } */
  .accordion {
display: block;
  }
  .accordionsummary{
    background-color: #00796b !important;
    justify-content: center !important;
    color: white !important;
  }
  .accordiondetails {
    background-color: #f0f0f0 !important;
    color: #595959 !important;
  }
  .svg_icons {
    transform: scale(1.8);
    margin: 10px !important
  }

  .view {
    background-color: #00796b;
    color: white;
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }
  
  th, td {
    border: 1px solid #00796b;
    text-align: left;
    padding: 8px;
    color: white; /* set text color to white */
  }
  
  th {
    background-color: #00796b;
  }
  
  td {
    color: #00796b; /* set td text color to white */
  }
  .about-header {
    font-family: 'Merriweather', serif !important;
    color: #595959 !important;
  }
  .about-text {
    font-family: 'Merriweather', serif !important;
    color: #595959 !important;
  }
  .about-list {
    font-family: 'Merriweather', serif !important;
    color: #595959 !important;
  }
  .contact-header {
    font-family: 'Merriweather', serif !important;
    color: #595959 !important;
  }
  .about-link {
    font-family: 'Merriweather', serif !important;
    color: #595959 !important;
  }